module.exports = {
    title: '青豆定制',
    subTitle: '青豆定制',
    logo: 'https://weixuewebchat.oss-cn-hangzhou.aliyuncs.com/banner/logo.png',
    /**
     * @type {boolean} true | false
     * @description Whether fix the header
     */
    fixedHeader: false,

    /**
     * @type {boolean} true | false
     * @description Whether show the logo in sidebar
     */
    sidebarLogo: true
}
