import Vue from "vue";
import container from './container.vue'
import store from '../../store/index'
let instance
const message = function (options){ // options是传入的参数配置 {message: '成功',type: "success"offset: 80}
    // 初始化容器
    let resetFlag = false
    let doms = document.getElementsByClassName('qingdou-message-contatiner')
    if (doms.length < 1) {
        resetFlag = true
    }
    if (options.top && !resetFlag) {
        let findOne = store.getters.messageContainers.find(item => item.top === options.top)

        if (!findOne) {
            resetFlag = true
        }
    }
    if (resetFlag) {
        console.log('渲染');
        let containerConstructor  = Vue.extend(container);
        const containerInstance = new containerConstructor({data: options}); // 将options导入至data
        // 模板渲染进行挂载
        containerInstance.$mount();
        // 添加到页面 dom
        document.body.appendChild(containerInstance.$el);
        let originMessageContainers = store.getters.messageContainers
        if (originMessageContainers == null) {
            originMessageContainers = []
        }
        originMessageContainers.push(containerInstance)
        store.commit('SET_MESSAGE_CONTAINERS', originMessageContainers);
    }
    instance = store.getters.messageContainers.find(item => item.top === options.top)
    instance.addChild(options)
};
["success", "error","info"].forEach(type => {
    message[type] = text => {
        const param = {
            type: type,
            message: text,
            top: 50
        }
        return message(param);
    };
});
export default message