import request from "@/utils/request";
import requestWapper from '@/api/requestWrapper'

export function login(data) {
  const headers = {
    "content-type": "multipart/form-data",
  };
  return request({
    headers,
    url: "/users/loginByPwd",
    method: "post",
    data,
  });
}
export function forgotPwd(data) {
  const headers = {
    "content-type": "multipart/form-data",
  };
  return request({
    headers,
    url: "/users/forgot",
    method: "post",
    data,
  });
}
export function loginByPhone(data) {
  let obj = requestWapper("/users/loginByPhone","post",data, 0)
  return request(obj);
}
export function regbyPwd(data) {
  let obj = requestWapper("/users/regByPwd","post",data, 0)
  return request(obj);
}

export function logout(data) {
  return request({
    url: `/users/${data.id}/logout`,
    method: "post",
  });
}

export function browsingHistoryQuery(params) {
  return request({
    url: `/browsingHistory/query`,
    method: "get",
    params,
  });
}

export function productCollectsByUserId(params) {
  return request({
    url: "/productCollects/byUserId",
    method: "get",
    params,
  });
}

/**
 * 修改用户信息
 */
export function updateProfile(data) {
  const headers = {
    "content-type": "multipart/form-data",
  };
  return request({
    headers,
    url: `/users/${data.id}/profile`,
    method: "post",
    data,
  });
}
/**
 * 修改用户手机号
 */
export function updatePhone(data) {
  const headers = {
    "content-type": "multipart/form-data",
  };
  return request({
    headers,
    url: `/users/${data.id}/updatePhone`,
    method: "post",
    data,
  });
}

/**
 * 修改用户密码
 */
export function updatePwd(data) {
  const headers = {
    "content-type": "multipart/form-data",
  };
  return request({
    headers,
    url: `/users/${data.id}/pwd`,
    method: "post",
    data,
  });
}

/**
 * 注销用户
 */
export function deleteUser(data) {
  const headers = {
    "content-type": "multipart/form-data",
    Authorization: "qingdou-server " + data.token,
  };
  return request({
    headers,
    url: `/users/${data.id}/delete`,
    method: "post",
    data,
  });
}

/**
 * 用户vip
 */

export function getUserVip(params) {
  return request({
    url: "/vip",
    method: "get",
    params,
  });
}
