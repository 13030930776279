import request from "../utils/request";

export function homeTop(params) {
  return request({
    url: "/banners/homeTop",
    method: "get",
    params,
  });
}
export function articles(params) {
  return request({
    url: "/articles",
    method: "get",
    params,
  });
}
export function entrances(params) {
  return request({
    url: "/entrances",
    method: "get",
    params,
  });
}
export function virtualCategories(params) {
  return request({
    url: "/virtualCategories/all",
    method: "get",
    params,
  });
}
export function getMerchantInfo(params) {
  return request({
    url: "/merchants",
    method: "get",
    params,
  });
}

/**
 * 获取商家关联信息
 */
export function getMerchantContact(params) {
  return request({
    url: "/merchants/contact",
    method: "get",
    params,
  });
}
