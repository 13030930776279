<template>
  <div>
    <a-affix
      class="affix"
      :offset-top="offsetTop"
      :style="{ top: offsetTop + 'px' }"
      v-if="showSideBar"
    >
      <div class="bar-box">
        <div class="list-item">
          <div @click="$router.push('/cat')" class="item" style="height: 124px">
            <a-icon class="icon primary-color" type="shopping-cart" />
            <div class="text" style="margin-top: 10px; padding: 0 11px">
              购物车
            </div>
          </div>
          <div class="line"></div>
          <div
            @click="$router.push('/collect')"
            class="item"
            style="height: 82px"
          >
            <a-icon class="icon primary-color" type="star" />
            <div class="text" style="margin-top: 6px">收藏</div>
          </div>
          <div class="line"></div>

          <div
            @click="$router.push('/order')"
            class="item"
            style="height: 98px"
          >
            <img class="icon" src="@/assets/img/我的订单@2x.png" />
            <div class="text" style="margin-top: 6px; padding: 0 8px">
              我的订单
            </div>
          </div>
        </div>
      </div>
      <div class="bar-box">
        <div class="list-item">
          <div @click="backTop" class="item" style="height: 98px">
            <a-icon class="icon" type="up-circle" />
            <div class="text" style="margin-top: 6px; padding: 0 8px">
              回到顶部
            </div>
          </div>

          <a-popover placement="left" arrow-point-at-center>
            <template slot="content">
              <div class="customer-service">
                <img :src="contact.manageWx" class="qrcode" />
              </div>
            </template>
            <div class="item" style="height: 98px">
              <a-icon class="icon" type="qrcode" />
              <div class="text" style="margin-top: 6px; padding: 0 8px">
                查单报价
              </div>
            </div>
          </a-popover>

          <a-popover placement="left" arrow-point-at-center>
            <template slot="content">
              <div class="customer-service serve-phone">
                <div>
                  <img
                    class="icon"
                    :src="require('@/assets/img/电话2@2x.png')"
                  />
                  <span class="phone">{{ contact.servePhone }}</span>
                </div>
                <div class="time">周一至周日09:00-18:00</div>
              </div>
            </template>
            <div class="item" style="height: 98px">
              <img class="icon" :src="require('@/assets/img/客服@2x.png')" />
              <div class="text" style="margin-top: 6px; padding: 0 8px">
                客服热线
              </div>
            </div>
          </a-popover>
        </div>
      </div>
      <!-- <a-button> to affix top </a-button> -->
    </a-affix>
  </div>
</template>

<script>
export default {
  data() {
    return {
      screenHeight: document.documentElement.clientHeight,
      offsetTop: 50,
      scrollTop: 0,
      merchant: {},
      contact: {},
    };
  },
  created() {},
  mounted() {
    var that = this;
    window.onresize = function () {
      // 定义窗口大小变更通知事件
      // that.screenWidth = document.documentElement.clientWidth // 窗口宽度
      that.screenHeight = document.documentElement.clientHeight; // 窗口高度
    };
    window.addEventListener("scroll", this.scrollToTop);
  },
  methods: {
    init() {
      this.$store.dispatch("GetContact").then((res) => {
        this.contact = res;
      });
      this.$store.dispatch("GetMerchant").then((res) => {
        this.merchant = res;
      });
    },
    backTop() {
      const that = this;
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed;
        if (that.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 16);
    }, // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
    scrollToTop() {
      const that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
    },
  },
  watch: {
    screenHeight: function (val) {
      // 监听屏幕高度变化
      this.offsetTop = (val - 624) / 2;
      if (this.offsetTop < 0) this.offsetTop = 0;
    },
  },
  computed: {
    showSideBar() {
      if (this.$store.state.user.token) {
        this.init();
        return true;
      }
      // return this.$store.state.user.token;
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.affix {
  position: absolute;
  z-index: 1;
  right: 0;
  .bar-box {
    width: 40px;
    height: 304px;
    background-color: rgba(255, 255, 255, 1);
    margin-top: 24px;
    // border-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    // box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 4px #ccc;
    .list-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      .line {
        background-color: rgba(0, 0, 0, 0.1);
        width: 24px;
        height: 1px;
      }
      .item {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
        user-select: none;
        .icon {
          margin-top: 15px;
          height: 30px;
          width: 30px;
          font-size: 30px;
        }

        .primary-color {
          color: #feb403;
        }
        .text {
          line-height: 16px;
          color: rgba(51, 51, 51, 100);
          font-size: 12px;
          text-align: center;
          font-family: PingFangSC-Regular;
        }
        // &:not(:last-child) {
        //   &:after {
        //     content: "";
        //     background-color: rgba(0, 0, 0, 0.1);
        //     width: 24px;
        //     height: 1px;
        //     position: absolute;
        //     bottom: 0;
        //   }
        // }
      }
    }
  }
}

.customer-service {
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .qrcode {
    width: 85px;
    height: 85px;
  }
  .icon {
    height: 17px;
    width: 17px;
  }
  .phone {
    color: rgba(51, 51, 51, 100);
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    margin-left: 6px;
  }
  .time {
    margin-top: 7px;
    color: rgba(102, 102, 102, 100);
    font-size: 9px;
    text-align: left;
    font-family: PingFangSC-Regular;
  }
}
</style>