import { getMerchantInfo, getMerchantContact } from "@/api/home";

const merchant = {
  state: {
    merchant: {},
    contact: {},
  },

  getters: {
    getInfo(state) {
      return state.merchant;
    },
  },
  mutations: {
    SET_MERCHANT: (state, merchant) => {
      state.merchant = merchant;
    },
    SET_CONTACT: (state, contact) => {
      state.contact = contact;
    },
  },

  actions: {
    // 登出
    GetMerchant({ commit }) {
      return new Promise((resolve, reject) => {
        const merchant = JSON.parse(sessionStorage.getItem("merchant"));
        // console.log("merchant", merchant);
        if (merchant && Object.keys(merchant).length !== 0) {
          resolve(merchant);
          return;
        }
        getMerchantInfo()
          .then((res) => {
            commit("SET_MERCHANT", res);
            sessionStorage.setItem("merchant", JSON.stringify(res));
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    GetContact({ commit }) {
      return new Promise((resolve, reject) => {
        const contact = JSON.parse(sessionStorage.getItem("contact"));
        // console.log("contact", contact);
        if (contact && Object.keys(contact).length !== 0) {
          commit("SET_CONTACT", contact);
          resolve(contact);
          return;
        }
        getMerchantContact()
          .then((res) => {
            commit("SET_CONTACT", res);
            sessionStorage.setItem("contact", JSON.stringify(res));
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};

export default merchant;
