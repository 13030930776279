const search = {
    state: {
        category: {},
        // 搜索字
        name: null,
        searchButton: false
    },

    mutations: {
        SET_CATEGORY: (state, category) => {
            state.category = category
        },
        SET_SEARCH_NAME: (state, name) => {
            state.name = name
        },
        SET_SEARCH_BUTTON: (state, button) => {
            state.searchButton = button
        }
    },

    actions: {

    }
}

export default search
