<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <router-view />
    </a-config-provider>
    <SideBar />
  </div>
</template>

<script>
import "@/assets/style/main.css";
import "@/assets/style/page.css";
import zhCN from "ant-design-vue/es/locale/zh_CN";

import SideBar from "@/components/SideBar";

export default {
  name: "App",
  components: { SideBar },
  data() {
    return {
      locale: zhCN,
    };
  },
  computed: {},
  created() {
    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload", (e) => {
      this.$store.commit("SET_MESSAGE_CONTAINERS", null);
      sessionStorage.setItem("userComMsg", JSON.stringify(this.$store.state));
    });

    //在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem("userComMsg")) {
      Object.assign(
        this.$store.state,
        JSON.parse(sessionStorage.getItem("userComMsg"))
      );
      //使用后清除内存
      setTimeout(function () {
        sessionStorage.removeItem("userComMsg");
      }, 300);
    }
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
</style>
