import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";
import user from "./modules/user";
import search from "./modules/search";
import merchant from "./modules/merchant";
import quotationData from "./modules/quotationData";
import message from "@/store/modules/message";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    search,
    quotationData,
    merchant,
    message
  },
  getters,
});
