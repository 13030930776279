import { login, loginByPhone, logout } from '@/api/user'

const user = {
    state: {
        token: '',
        name: '',
        avatar: '',
        info: {}
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_NAME: (state, {name}) => {
            state.name = name
        },
        SET_AVATAR: (state, avatar) => {
            state.avatar = avatar
        },
        SET_INFO: (state, info) => {
            state.info = info
        }
    },

    actions: {
        // 登录
        Login ({commit}, userInfo) {
            return new Promise((resolve, reject) => {
                login(userInfo).then(response => {
                    sessionStorage.setItem("token", response.session.token)
                    sessionStorage.setItem("info", JSON.stringify(response))
                    commit('SET_TOKEN', response.session.token)
                    commit('SET_INFO', response)
                    commit('SET_NAME', {name: response.nickname})
                    commit('SET_AVATAR', response.avatar)
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 登录
        LoginByPhone ({commit}, userInfo) {
            return new Promise((resolve, reject) => {
                loginByPhone(userInfo).then(response => {
                    sessionStorage.setItem("token", response.session.token)
                    sessionStorage.setItem("info", JSON.stringify(response))
                    commit('SET_TOKEN', response.session.token)
                    commit('SET_INFO', response)
                    commit('SET_NAME', {name: response.nickname})
                    commit('SET_AVATAR', response.avatar)
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 登出
        Logout ({commit}) {
            return new Promise((resolve, reject) => {
                const info = JSON.parse(sessionStorage.getItem("info"))
                logout({id:info.id}).then(() => {
                    commit('SET_TOKEN', '')
                    sessionStorage.removeItem('token')
                    sessionStorage.removeItem('info')
                    resolve()
                }).catch(err => {
                    commit('SET_TOKEN', '')
                    sessionStorage.removeItem('token')
                    sessionStorage.removeItem('info')
                    reject(err)
                    // resolve()
                }).finally(err => {
                    commit('SET_TOKEN', '')
                    sessionStorage.removeItem('token')
                    sessionStorage.removeItem('info')
                    sessionStorage.removeItem('merchant')
                    sessionStorage.removeItem('contact')
                    reject(err)
                })
            })
        }

    }
}

export default user
