<!-- message容器 -->
<template>
  <div class="qingdou-message-contatiner" :style="{top: top+'%'}">
    <transition-group appear>
      <div class="qingdou-message" :class="['my-message', 'my-message-' + item.type, index === 0 ? '' : 'topMargin']" v-for="(item,index) in list" :key="item.uid">
        <div :key="item.uid" class="qingdou-message-text">
          {{ item.message }}
        </div>
      </div>
    </transition-group>
  </div>
</template>
<script>
export default {
  data(){
    return{
      top: 0,
      list:[],
      uid: 0,
      visible: true
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  methods:{
    addChild(options){
      let param = {
        duration: 3000,
        timer: null,
        message: '',
        type: 'info',
        closed: false,
        uid: ++this.uid
      }
      // 定时器自动关闭
      param.timer = setTimeout(() => {
        this.removeChild(param.uid)
      }, param.duration);
      param = {...param, ...options}
      let messageList =  JSON.parse(JSON.stringify(this.list))
      messageList.push(param)
      this.list = [...messageList]
    },
    removeChild(uid){
      this.list = this.list.filter(item => item.uid !== uid);
    },

  },
}
</script>
<style lang="scss">
.v-enter-active{
  transition: all 0.5s ease;
  transform: translateY(-15px);
  opacity: 0;
}
.v-leave-active {
  transform: translateY(-20px);
  transition: all 0.5s ease;
  opacity: 0;
}


.topMargin{
  margin-top: 20px;
}
.qingdou-message-contatiner{
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(-50%);
  list-style: none;
  position: fixed;
  top: 16px;
  left: 0;
  z-index: 10006;
  width: 100%;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
  }
  .my-message-info {
    color: #909399;
  }
  .my-message-success {
    background: #f2f9ec;
    color: #67c23a;
    border-color: #e4f2da;
  }
  .my-message-warning {
    background: #fcf6ed;
    color: #e6a23c;
    border-color: #f8ecda;
  }
  .my-message-error {
    background: #fcf0f0;
    color: #f56c6c;
    border-color: #f9e3e2;
  }
  .qingdou-message{
    min-width: 148px;
    background-color: #edf2fc;
    transition: opacity 0.3s, transform 0.4s, top 0.4s;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    z-index: 10006;
    height: 48px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.7);
    .qingdou-message-text{
      color: rgba(255, 255, 255, 100);
      font-size: 14px;
      text-align: center;
      font-family: PingFangSC-Regular;
    }
  }
}
</style>