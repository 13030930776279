const getters = {
  token: state => sessionStorage.getItem("token"),
  category: state => state.search.category,
  searchName: state => state.search.name,
  searchButton: state => state.search.searchButton,
  quotationData: state => state.quotationData.one,
  quotationProduct: state => state.quotationData.productDetail,
  quotationPreview: state => state.quotationData.orderPreview,
  quotationPreviewId: state => state.quotationData.previewParamId,
  quotationPreviewType: state => state.quotationData.previewType,
  quotationOrder: state => state.quotationData.order,
  messageContainers: state => state.message.messageContainers,
  cartId: state => state.quotationData.cartId
};
export default getters;