import Vue from 'vue'
import moment from 'moment'
import storage from 'store'
import QingDouMessage from "@/components/qingdouMessage/main.js";
// 自定义的消息展示

Vue.prototype.$QingDouMessage = QingDouMessage

Vue.prototype.$dateFormat = (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') => {
  return moment(dataStr).format(pattern)
}

Vue.prototype.$quotationNum = (record, id = 0) => {
  const prefix = record.productType == 'PRODUCT' ? 'zy' : 'zg'
  return prefix + moment(record.createAt).format('YYYYMMDDHHmmss') + id.toString().padStart(5, '0')
}

Vue.prototype.$quotationPointNum = (record, id = 0) => {
  const prefix = 'jf'
  return prefix + moment(record.createAt).format('YYYYMMDDHHmmss') + id.toString().padStart(5, '0')
}

Vue.prototype.$specialStrFilter = str => {
  // eslint-disable-next-line no-useless-escape
  const specialStrFilterReg = /[`~!@#_$%^&*()=|{}':;',\\\[\\\]<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？\s]/g
  return str.replace(specialStrFilterReg, '')
}

Vue.prototype.$sortByName = (v1, v2) => {
  // eslint-disable-next-line no-useless-escape
  let a = v1.name
  let b = v2.name
  const reg = /[0-9]+/g
  const lista = a.match(reg)
  const listb = b.match(reg)
  if (!lista || !listb) {
    return a.localeCompare(b)
  }
  for (const i = 0, minLen = Math.min(lista.length, listb.length); i < minLen; i++) {
    // 数字所在位置序号
    const indexa = a.indexOf(lista[i])
    const indexb = b.indexOf(listb[i])
    // 数字前面的前缀
    const prefixa = a.substring(0, indexa)
    const prefixb = a.substring(0, indexb)
    // 数字的string
    const stra = lista[i]
    const strb = listb[i]
    // 数字的值
    const numa = parseInt(stra)
    const numb = parseInt(strb)
    // 如果数字的序号不等或前缀不等，属于前缀不同的情况，直接比较
    if (indexa !== indexb || prefixa !== prefixb) {
      return a.localeCompare(b)
    } else {
      // 数字的string全等
      if (stra === strb) {
        // 如果是最后一个数字，比较数字的后缀
        if (i === minLen - 1) {
          return a.substring(indexa).localeCompare(b.substring(indexb))
        }
        // 如果不是最后一个数字，则循环跳转到下一个数字，并去掉前面相同的部分
        else {
          a = a.substring(indexa + stra.length)
          b = b.substring(indexa + stra.length)
        }
      }
      // 如果数字的string不全等，但值相等
      else if (numa === numb) {
        // 直接比较数字前缀0的个数，多的更小
        return strb.lastIndexOf(numb + '') - stra.lastIndexOf(numa + '')
      } else {
        // 如果数字不等，直接比较数字大小
        return numa - numb
      }
    }
  }
}

Vue.prototype.$storage = storage

/**
 * 判断值不为undefined、NULL、''
 * @param val
 * @returns {boolean}
 */
Vue.prototype.$available = val => {
  return val !== undefined && val != null && val !== ''
}
