import axios from "axios";
import { MessageBox, Message } from "element-ui";
import store from "@/store";
import { getToken } from "@/utils/auth";
import Base64 from "./base64Code";

export function domainMatch(url) {
  const domainPatt = "(http|https):\\/\\/(.*?)\\.kingdovip\\.com";
  const match = url.match(domainPatt);
  if (match == null || match.length < 3) {
    return null;
  }
  if (match[2] === "api-web") {
    return null;
  }
  return "/shop-" + match[2];
}

export function urlMatch(url) {
  const patt = "/shop.*?/#*";
  let appendUrl = "";
  const match = url.match(patt);
  if (match != null && match.length > 0) {
    appendUrl = match[0];
    if (appendUrl[appendUrl.length - 1] === "#") {
      appendUrl = appendUrl.substr(0, appendUrl.length - 1);
    }
    if (appendUrl[appendUrl.length - 1] === "/") {
      appendUrl = appendUrl.substr(0, appendUrl.length - 1);
    }
  }
  return appendUrl;
}

export function getUrl() {
  const url = window.location.href;
  const domainUrl = domainMatch(url);
  if (domainUrl != null) {
    return domainUrl + "/v1";
  }
  const appendUrl = urlMatch(url);
  if(appendUrl != ''){
    return appendUrl + "/v1";
  }else{
    return '/shop-test/v1';
  }
}

export function getPreUrl() {
  const url = window.location.href;
  const domainUrl = domainMatch(url);
  if (domainUrl != null) {
    return domainUrl;
  }
  const appendUrl = urlMatch(url);
  return appendUrl;
}

export function jump() {
  const url = window.location.href;
  const domainUrl = domainMatch(url);
  if (domainUrl != null) {
    return "";
  }
  const appendUrl = urlMatch(url);
  return appendUrl;
}

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API + getUrl(), // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    let userInfo = JSON.parse(sessionStorage.getItem("info"));
    if (userInfo) {
      let code = new Base64();
      let token = userInfo.id + ":" + userInfo.session.token;
      token = code.encode(token);
      config.headers["Authorization"] = "qingdou-server " + token;
    } else {
      config.headers["Authorization"] =
        "qingdou-server MDplenViYWR1MDZ6MncwbTBxMDd0a3B5eTlnam9paHZ2NA==";
    }
    return config;
  },
  (error) => {
    // do something with request error
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data;
    return res;
    // if the custom code is not 20000, it is judged as an error.
    // if (res.code !== 200) {
    //     Message({
    //         message: res.message || 'Error',
    //         type: 'error',
    //         duration: 5 * 1000
    //     })
    //
    //     // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    //     if (res.code === 508 || res.code === 512 || res.code === 514) {
    //         // to re-login
    //         MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
    //             confirmButtonText: 'Re-Login',
    //             cancelButtonText: 'Cancel',
    //             type: 'warning'
    //         }).then(() => {
    //             store.dispatch('user/resetToken').then(() => {
    //                 location.reload()
    //             })
    //         })
    //     }
    //     return Promise.reject(new Error(res.message || 'Error'))
    // } else {
    //     return res
    // }
  },
  (error) => {
    Message({
      message: error.response.data.message,
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export default service;

export { service as axios };
