const quotationData = {
    state: {
        // 报价单生成或修改后选择保存的数据
        one:null,
        // 商品详情缓存
        productDetail: null,
        // 下单预览缓存
        orderPreview: null,
        // 下单参数(普通下单为报价单Id,购物车为购物车Ids)
        previewParamId: null,
        // 报价单正式下单
        order: null,
        // 下单方式 0直接下单  1购物车下单
        previewType: null,
        // 查看报价单后确认下单
        cartId: null,
    },

    mutations: {
        SET_QUOTATION: (state, quotation) => {
            state.one = quotation
        },
        SET_PRODUCT_DETAIL: (state, productDetail) => {
            state.productDetail = productDetail
        },
        SET_ORDER_REVIEW: (state, orderPreview) => {
            state.orderPreview = orderPreview
        },
        SET_PREVIEW_PARAM_ID: (state, previewParamId) => {
            state.previewParamId = previewParamId
        },
        SET_ORDER_CREATE: (state, order) => {
            state.order = order
        },
        SET_ORDER_PREVIEW_TYPE: (state, type) => {
            state.previewType = type
        },
        SET_CART_ID: (state, cartId) => {
            state.cartId = cartId
        }
    },

    actions: {

    }
}

export default quotationData
