const message = {
    state: {
        // 消息弹框容器
       messageContainers: null
    },

    mutations: {
        SET_MESSAGE_CONTAINERS: (state, messageContainers) => {
            state.messageContainers = messageContainers
        }
    },

    actions: {

    }
}

export default message
